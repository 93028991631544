@import '~@angular/material/theming';
// Plus imports for other components in your app.

$dc-color-primary: #1d2e3c;
$dc-color-primary-variant: #0074ff;
$dc-color-accent: #e6e7e8;
$dc-color-accent-variant: #e6e7e8;
$dc-color-disabled: #8B8E94;
$dc-color-disabled-variant: #8B8E94;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dc-color-palette-primary: (
        50 : #e3e8e9,
        100 : $dc-color-primary-variant,
        200 : #8ca1a2,
        300 : #5e7b7d,
        400 : #3c5e61,
        500 : $dc-color-primary,
        600 : #163c3e,
        700 : #123336,
        800 : #0e2b2e,
        900 : #081d1f,
        A100 : #5df5ff,
        A200 : #2af2ff,
        A400 : #00e7f6,
        A700 : #00cfdd,
        contrast: (
                50 : $dc-color-primary-variant,
                100 : $dc-color-primary-variant,
                200 : $dc-color-primary-variant,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : $dc-color-primary-variant,
                A200 : $dc-color-primary-variant,
                A400 : $dc-color-primary-variant,
                A700 : $dc-color-primary-variant,
        )
);

$dc-color-palette-accent: (
        50 : #f9fefc,
        100 : $dc-color-accent-variant,
        200 : #e7f9f1,
        300 : #ddf7eb,
        400 : #d5f5e7,
        500 : $dc-color-accent-variant,
        600 : #c9f1e0,
        700 : #c2efdc,
        800 : #bcedd8,
        900 : #b0ead0,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
        contrast: (
                50 : $dc-color-primary,
                100 : $dc-color-primary,
                200 : $dc-color-primary,
                300 : $dc-color-primary,
                400 : $dc-color-primary,
                500 : $dc-color-primary,
                600 : $dc-color-primary,
                700 : $dc-color-primary,
                800 : $dc-color-primary,
                900 : $dc-color-primary,
                A100 : $dc-color-primary,
                A200 : $dc-color-primary,
                A400 : $dc-color-primary,
                A700 : $dc-color-primary,
        )
);

$dc-palette-primary: mat-palette($dc-color-palette-primary);
$dc-palette-accent: mat-palette($dc-color-palette-accent);
$dc-palette-warn: mat-palette($mat-red);

$dc-foreground: (
        base: $dc-color-primary,
        divider: $dc-color-primary-variant,
        dividers: $dark-dividers,
        disabled: $dark-disabled-text,
        disabled-button: rgba(black, 0.26),
        disabled-text: $dark-disabled-text,
        elevation: $dc-color-primary,
        hint-text: $dark-disabled-text,
        secondary-text: $dc-color-primary-variant,
        icon: $dc-color-primary,
        icons: blue,
        text: $dc-color-primary,
        slider-min: rgba(black, 0.87),
        slider-off: rgba(black, 0.26),
        slider-off-active: rgba(black, 0.38),
);

$dc-background: (
        status-bar: map-get($mat-grey, 300),
        app-bar: map-get($mat-grey, 100),
        background: map-get($mat-grey, 50),
        hover: rgba(black, 0.04), // TODO(kara): check style with Material Design UX
        card: white,
        dialog: white,
        disabled-button: rgba(black, 0.12),
        raised-button: white,
        focused-button: $dark-focused,
        selected-button: map-get($mat-grey, 300),
        selected-disabled-button: map-get($mat-grey, 400),
        disabled-button-toggle: map-get($mat-grey, 200),
        unselected-chip: map-get($mat-grey, 300),
        disabled-list-option: map-get($mat-grey, 200),
        tooltip: map-get($mat-grey, 700),
);

@function dc-light-theme($primary, $accent, $warn, $foreground, $background) {
  @return (
          primary: $primary,
          accent: $accent,
          warn: $warn,
          is-dark: false,
          foreground: $foreground,
          background: $background,
  );
}

// Create the theme object (a Sass map containing all of the palettes).
$dc-app-theme: dc-light-theme(
                $dc-palette-primary,
                $dc-palette-accent,
                $dc-palette-warn,
                $dc-foreground,
                $dc-background
);

$custom-typography: mat-typography-config(
  /* $font-family: 'SofiaPro-Regular, SofiaPro-Medium' */
  $font-family: "'Poppins', sans-serif"
);

@include mat-base-typography($custom-typography);
@include mat-checkbox-typography($custom-typography);
@include angular-material-typography($custom-typography);
@include mat-core($custom-typography);

// Compute font config
@include angular-material-theme($dc-app-theme);

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
// Default variable overrides

// Required
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// theme
:root {

  // Specific Direct Collect style

  .dc-primary {
    color: $dc-color-primary !important;
  }

  .dc-primary-variant {
    color: $dc-color-primary-variant !important;
  }

  .dc-accent {
    color: $dc-color-accent !important;
  }

  .img-logo {
    height: 24px; /* adjust as needed */
    object-fit: contain;
  }

  .img-user {
    height: 24px; /* adjust as needed */
    //object-fit: contain;
  }

  .dc-container {
    padding: 30px;
  }

  .dc-card {
    box-shadow: none;
    background-color: $dc-color-accent;
  }

  .dc-carousel {
    box-shadow: none;
    border: 1px solid $dc-color-accent
  }

  .carousel .carousel-indicators li { background-color: $dc-color-accent}
  .carousel .carousel-indicators li.active { background-color: $dc-color-accent}

  // Override material components

  .mat-icon, .mat-h1, .mat-h2, .mat-h3, .mat-h4, .mat-h5, .mat-h6, .mat-card {
    color: $dc-color-primary;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $dc-color-primary-variant;
  }

  .mat-stroked-button.mat-accent,{
    color: $dc-color-primary-variant !important;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border-radius: 0 !important;
  }

  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button,
  .mat-button-disabled {
    border-radius: 50px;
  }

  .mat-divider {
    border-color: $dc-color-accent;
  }

  .mat-progress-bar {
    height: 6px;
  }

  .mat-progress-bar-fill::after {
    background-color: $dc-color-primary-variant;
  }

  .mat-progress-bar-buffer {
    background-color: $dc-color-accent-variant;
  }

  .mat-card {
    border-radius: 12px;
  }

  .mat-radio-outer-circle {
    border-color: $dc-color-primary-variant;
  }

  .mat-radio-inner-circle {
    background-color: $dc-color-primary !important;
  }

  .mat-pseudo-checkbox-checked {
    background-color: $dc-color-primary;
  }

  .mat-form-field {
    min-width: 80px;
    width: 100%;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $dc-color-primary !important;
  }

  .mat-radio-checked {
    border: 1px $dc-color-primary solid;
  }

  .mat-radio-button {
    color: $dc-color-primary-variant
  }

  .mat-bottom-sheet-container {
    border-radius: 20px 20px 0 0;
  }
  .mat-snack-bar-container {
    margin: 20px 20px !important;
    color: $dc-color-primary;
    background-color: white;
    border-radius: 0;
    min-height: 0;
    padding: 0;
  }


  // Override bootstrap

  body {
    color: $dc-color-primary;
  }

  a {
    color: $dc-color-primary-variant
  }


  --bs-01-color-primary: #1d2e3c;
  --bs-02-color-primary: #500085;

  --bs-01-color-basic: #fff;
  --bs-02-color-basic: #fff;

  --bs-01-color-darkest: #000;
  --bs-01-color-dark: #343a40;

  --bs-02-color-darkest: #000;
  --bs-02-color-dark: #343a40;

  --bs-01-color-medium: #f0f0f0;
  --bs-02-color-medium: #f0f0f0;

  // Text
  --bs-01-text-color-primary: #fff;
  --bs-01-text-color-inverted-primary: #000;

  --bs-02-text-color-primary: #fff;
  --bs-02-text-color-inverted-primary: #000;

  --bs-01-text-size-tiny: 0.8em;
  --bs-01-text-size-title: 1.5em;
  --bs-01-text-size-subtitle: 1.2em;

  --bs-02-text-size-tiny: 0.8em;
  --bs-02-text-size-title: 1.5em;
  --bs-02-text-size-subtitle: 1.2em;

  --bs-01-text-weight-title: 500;
  --bs-01-text-weight-subtitle: 400;
  --bs-01-text-weight-normal: 200;
  --bs-01-text-weight-bold: 600;
  --bs-01-text-weight-heavy: 450;

  --bs-02-text-weight-title: 500;
  --bs-02-text-weight-subtitle: 400;
  --bs-02-text-weight-normal: 200;
  --bs-02-text-weight-bold: 600;
  --bs-02-text-weight-heavy: 450;

  --bs-01-text-size-normal: 1em;
  --bs-02-text-size-normal: 1em;

  --bs-01-text-size-small: 0.8em;
  --bs-02-text-size-small: 0.8em;

  --bs-01-text-size-panel-subtitle: 1em;
  --bs-02-text-size-panel-subtitle: 1em;

  --bs-01-text-size-panel-title: 1.2em;
  --bs-02-text-size-panel-title: 1.2em;

  --bs-01-text-size-amount: 1.5em;
  --bs-02-text-size-amount: 1.5em;

  // Background
  --bs-01-background-color-default: #ffffff;
  --bs-02-background-color-default: #ffffff;

  --bs-background-color-light: #c0c0c0;
  --bs-01-background-color-light: #c0c0c0;
  --bs-02-background-color-light: #c0c0c0;

  --bs-background-color-dark: #6c757d;
  --bs-01-background-color-dark: #6c757d;
  --bs-02-background-color-dark: #6c757d;

  // --bs-01-background-color-lighter:#ECECEC;
  // --bs-01-background-color-medium:#c0c0c0;;
  // --bs-01-background-color-dark:#363B39;

  // --bs-02-background-color-lighter:#ECECEC;
  // --bs-02-background-color-medium:#F0F0F0;
  // --bs-02-background-color-dark:#363B39;

  //  Border
  --bs-01-border-radius: 0.5em;
  --bs-02-border-radius: 0.5em;

  --bs-01-border-color-dark: #c0c0c0;
  --bs-01-border-color-darker: #707070;

  --bs-02-border-color-dark: #c0c0c0;
  --bs-02-border-color-darker: #707070;

  --bs-background-color-lighter: #fbfbfc;
  --bs-01-background-color-lighter: #fbfbfc;
  --bs-02-background-color-lighter: #fbfbfc;

  --bs-01-border-color-dark: #c0c0c0;
  --bs-01-border-color-darker: #707070;

  --bs-02-border-color-dark: #c0c0c0;
  --bs-02-border-color-darker: #707070;

  // Icons
  --bs-01-icon-padding: 0.6em;
  --bs-02-icon-padding: 0.6em;

  --bs-dark: #343a40;

  --bs-background-light: #f0f0f0;
  --bs-background-dark: #363b39;

  --bs-text-panel-title: 1.2em;
  --bs-text-panel-subtitle: 1em;

  --bs-text-primary: #fff;
  --bs-text-inverted-primary: #000;
}

// custom style reboot
body {
  background-color: #ffffff;
}

//  ------------------------------------------------------
// custom Backyard cash classes
// ------------------------------------------------------

//  --------------------------------------------------------------
//  ------------------  Common classes
.byc-icon-x2 {
  transform: scale(2);
}

.byc-hidden {
  display: none;
  visibility: hidden;
}

.byc-no-border {
  border: none;
}

.byc-01-background-light {
  background-color: var(--bs-01-background-color-lighter);
}

.byc-02-background-light {
  background-color: var(--bs-02-background-color-lighter);
}

//  ------------------  Generic formatting
.byc-01-primary {
  background-color: var(--bs-01-color-primary);
  color: var(--bs-01-text-color-primary);
}

.byc-02-primary {
  background-color: var(--bs-02-color-primary);
  color: var(--bs-02-text-color-primary);
}

.byc-01-color-primary {
  color: var(--bs-01-color-primary);
}

.byc-02-color-primary {
  color: var(--bs-02-color-primary);
}

//  --------------------------------------------------------------
//  ------------------  Text formatting

.byc-01-text-normal {
  font-weight: var(--bs-01-text-weight-normal);
  font-size: var(--bs-01-text-size-normal);
  line-height: 1.3em;
}

.byc-02-text-normal {
  font-weight: var(--bs-02-text-weight-normal);
  font-size: var(--bs-02-text-size-normal);
  line-height: 1.3em;
}

.byc-01-text-title {
  font-weight: var(--bs-01-text-weight-title);
  font-size: var(--bs-01-text-size-title);
  line-height: 1.3em;
}

.byc-01-text-subtitle {
  font-weight: var(--bs-01-text-weight-subtitle);
  font-size: var(--bs-01-text-size-subtitle);
}

.byc-01-text-title {
  font-weight: var(--bs-01-text-weight-title);
  font-size: var(--bs-01-text-size-title);
  line-height: 1.3em;
}

.byc-01-text-heavy {
  font-weight: var(--bs-01-text-weight-heavy);
}

.byc-02-text-heavy {
  font-weight: var(--bs-02-text-weight-heavy);
}

.byc-02-text-title {
  font-weight: var(--bs-02-text-weight-title);
  font-size: var(--bs-02-text-size-title);
  line-height: 1.3em;
}

.byc-02-text-subtitle {
  font-weight: var(--bs-02-text-weight-subtitle);
  font-size: var(--bs-02-text-size-subtitle);
}

.byc-01-text-tiny {
  font-weight: var(--bs-01-text-weight-subtitle);
  font-size: var(bs-01-text-size-subtitle);
  font-size: var(--bs-01-text-size-tiny);
  color: var(--bs-01-color-dark);
}

.byc-02-text-tiny {
  font-weight: var(--bs-02-text-weight-subtitle);
  font-size: var(bs-02-text-size-subtitle);
  font-size: var(--bs-02-text-size-tiny);
  color: var(--bs-02-color-dark);
}

.byc-01-text-username {
  font-weight: var(--bs-01-text-weight-bold);
}

.byc-02-text-username {
  font-weight: var(--bs-02-text-weight-bold);
}

.byc-01-text-amount {
  font-size: var(--bs-01-text-size-amount);
}

.byc-02-text-amount {
  font-size: var(--bs-02-text-size-amount);
}

.byc-01-text-primary {
  color: var(--bs-01-text-color-primary);
}

.byc-02-text-primary {
  color: var(--bs-02-text-color-primary);
}

.byc-01-text-basic {
  font-weight: var(--bs-01-text-weight-normal);
}

.byc-01-text-bold {
  font-weight: var(--bs-01-text-weight-bold);
}

.byc-02-text-bold {
  font-weight: var(--bs-02-text-weight-bold);
}

.byc-01-text-panel-title {
  font-weight: var(--bs-01-text-weight-title);
  font-size: var(--bs-01-text-size-title);
}

.byc-02-text-panel-title {
  font-weight: var(--bs-02-text-weight-title);
  font-size: var(--bs-02-text-size-title);
}

.byc-01-text-panel-subtitle {
  font-weight: var(--bs-01-text-weight-subtitle);
  font-size: var(--bs-01-text-size-subtitle);
}

.byc-02-text-panel-subtitle {
  font-weight: var(--bs-02-text-weight-subtitle);
  font-size: var(--bs-02-text-size-subtitle);
}

//  ------------------  Form fields
.byc-01-form-field {
  width: 100%;
}

.byc-02-form-field {
  width: 100%;
}

.byc-01-form-field .mat-form-field-flex {
  border: 1px solid var(--bs-01-background-color-light);
}

.byc-02-form-field .mat-form-field-flex {
  border: 1px solid var(--bs-02-background-color-light);
}

//  ------------------  Buttons
.byc-01-next {
  padding: 0.5em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  font-size: 1.5em;
  border-radius: 0.5em !important;
  margin-bottom: 0.5em !important;
  margin-left: auto !important;
  margin-right: auto !important;

  min-width: 80%;
}

.byc-02-next {
  padding: 0.5em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  font-size: 1.5em;
  border-radius: 0.5em !important;
  margin-bottom: 0.5em !important;
  margin-left: auto !important;
  margin-right: auto !important;

  min-width: 80%;
}

//  --------------------------------------------------------------
//  ------------------  Containers
.byc-01-user-home-main-container {
  background-color: var(--bs-01-color-primary);
  height: 100vh;
}

.byc-02-user-home-main-container {
  background-color: var(--bs-02-color-primary);
  height: 100vh;
}

.byc-01-header-strip {
  height: 7vh;
  max-height: 7vh;
  min-height: 7vh;
  background-color: var(--bs-01-background-color-lighter);
}

.byc-02-header-strip {
  height: 7vh;
  max-height: 7vh;
  min-height: 7vh;
  background-color: var(--bs-01-background-color-lighter);
}

.byc-01-notification-strip {
  height: 20vh;
  max-height: 20vh;
  min-height: 20vh;
}

.byc-02-notification-strip {
  height: 20vh;
  max-height: 20vh;
  min-height: 20vh;
}

.byc-01-notifications-panel {
  background-color: var(--bs-01-background-color-dark);
  border-radius: 0.5em;
  padding: 1em;
  color: var(--bs-01-color-basic);
}

.byc-02-notifications-panel {
  background-color: var(--bs-02-background-color-dark);
  border-radius: 0.5em;
  padding: 1em;
  color: var(--bs-02-color-basic);
}

.byc-01-main-strip {
  padding-left: 0.3em;
  padding-right: 0.3em;
  height: 73vh;
  max-height: 73vh;
  min-height: 73vh;
}

.byc-02-main-strip {
  padding-left: 0.3em;
  padding-right: 0.3em;
  height: 73vh;
  max-height: 73vh;
  min-height: 73vh;
}

.byc-01-main-panel {
  border: 1px solid var(--bs-01-background-color-dark);
  border-radius: 0.5em;
  height: 100%;
  border: none;
}

.byc-02-main-panel {
  border: 1px solid var(--bs-02-background-color-dark);
  border-radius: 0.5em;
  height: 100%;
  border: none;
}

.byc-01-main-action-tiles {
  height: 25%;
}

.byc-02-main-action-tiles {
  height: 25%;
}

.byc-01-panel {
  border: 1px solid var(--bs-01-border-color-dark);
  border-radius: var(--bs-01-border-radius);
}

.byc-02-panel {
  border: 1px solid var(--bs-02-border-color-dark);
  border-radius: var(--bs-02-border-radius);
}

.byc-01-actiontile {
  text-align: center;
  min-height: 7em;
  max-height: 7em;
  border-radius: 0.5em;
  padding: 0.5em;

  border: 1px solid var(--bs-01-border-color-darker);
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5em;
  opacity: 1;
}

.byc-01-actiontile:hover {
  cursor: pointer;
}

.byc-02-actiontile {
  text-align: center;
  min-height: 7em;
  max-height: 7em;
  border-radius: 0.5em;
  padding: 0.5em;

  border: 1px solid var(--bs-01-border-color-darker);
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5em;
  opacity: 1;
}

.byc-02-actiontile:hover {
  cursor: pointer;
}

.byc-01-main-account-stats {
  background-color: var(--bs-01-color-primary);
  border-radius: var(--bs-01-border-radius);
  height: 25%;
  border: none;
}

.byc-02-main-account-stats {
  background-color: var(--bs-02-color-primary);
  border-radius: var(--bs-02-border-radius);
  height: 25%;
  border: none;
}

.byc-01-tile-without-border {
  border: none;
  border-radius: var(--bs-01-border-radius);
}

.byc-02-tile-without-border {
  border: none;
  border-radius: var(--bs-02-border-radius);
}

.byc-01-main-featured-panel {
  height: 50%;
}

.byc-02-main-featured-panel {
  height: 50%;
}

.byc-01-charitytile {
  background-color: var(--bs-01-color-basic);
  min-height: 10em;
  border-radius: var(--bs-01-border-radius);
  padding: 1em;
  color: var(--bs-01-color-darkest);
  height: 100%;
  border: 1px solid var(--bs-01-background-color-dark);
}

.byc-02-charitytile {
  background-color: var(--bs-02-color-basic);
  min-height: 10em;
  border-radius: var(--bs-02-border-radius);
  padding: 1em;
  color: var(--bs-02-color-darkest);
  height: 100%;
  border: 1px solid var(--bs-02-background-color-dark);
}

//  --------------------------------------------------------------
//  ------------------  Background

.byc-01-background-lighter {
  background-color: var(--bs-01-background-color-lighter);
}

.byc-02-background-lighter {
  background-color: var(--bs-02-background-color-lighter);
}

//  --------------------------------------------------------------
//  ------------------  Icons and Images

.byc-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.byc-icon-white {
  color: white;
}

.byc-icon-black {
  color: black;
  padding: var(--bs-01-icon-padding);
}

.byc-icon-cancel {
  padding: var(--bs-01-icon-padding);
}

.byc-01-image-default {
  height: 32px;
}

.byc-02-image-default {
  height: 32px;
}

.byc-01-image-charity-logo {
  height: 128px;
}

.byc-02-image-charity-logo {
  height: 128px;
}

//  --------------------------------------------------------------
//  ------------------  Radio/Option

.byc-radio-button-small {
  border: 1px solid $dc-color-primary-variant;
  border-radius: 0;
  padding: 1em;
  margin-top: 0.5em;

  font-size: 1em;
  font-weight: 500;
}

.byc-radio-button-small .mat-radio-container {
  margin-right: 0;
  margin-left: auto;

  margin-top: 0;
  margin-bottom: auto;
}

.byc-radio-button-small .mat-radio-label-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.byc-radio-button-small-body {
  font-weight: 400;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.byc-check-button-small {
  border: 1px solid $dc-color-primary-variant;
  border-radius: 0;
  padding: 1em;
  margin-top: 0.5em;

  font-size: 1em;
  font-weight: 500;
}

.byc-check-button-small .mat-list-text {
  font-size: var(--bs-01-text-size-small);
  font-weight: var(--bs-01-text-weight-subtitle);
}

.byc-check-button-hidden .mat-pseudo-checkbox {
  display: none !important;
}

//  --------------------------------------------------------------
//  ------------------  Date picker
.byc-datepicker-available-dates {
  font-weight: var(--bs-01-text-weight-bold);
  background: rgba(83, 83, 83, 0.1);
  border-radius: 100%;
}

//  --------------------------------------------------------------
//  ------------------  Alerts  - updated 2021-10-26: CJ
//  --------------------------------------------------------------
.byc-alert-container {
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  padding: 0.5em;
  border-radius: 0 0 0.3em 0.3em;
  display: flex;
  justify-content: center;
  min-height: 100px;
  overflow-y: hidden;
  transition-property: all;
  transition-duration: 1s;
  word-break: break-all;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  padding: 0.5em;
}

.byc-alert-inner-container {
  width: 100%;
  border: 1px solid rgb(184, 184, 184);
  border-radius: 0.25em;
}

.byc-01-alert-message {
  line-height: 1.5em;
  font-size: 1em;
  color: white;
  padding: 0.5em;
}

.byc-02-alert-message {
  line-height: 1.5em;
  font-size: 1em;
  color: white;
  padding: 0.5em;
}

.byc-alert-info {
  background-color: cornflowerblue;
}

.byc-alert-warn {
  background-color: #e85d04;
}

.byc-alert-err {
  background-color: crimson;
}

@import "./spinner";
